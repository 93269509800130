import React from 'react';
import PropTypes from 'prop-types';
import UploadThumbnail from '../UploadThumbnail/UploadThumbnail';
import  { RESOURCE_TYPES } from './ExistentResSelector';

const NewOrExistentResSwitch = ({
    id,
    label,
    fileChange,
    accept,
    error,
    existentItems,
    type,
    defaultValue,
}) => {
    
    return (
        <div className="relative w-full">
            <div className="w-full flex justify-between items-center">
                <p className={`${error && 'text-red-600'}`}>{label}</p>
            </div>
            {
                <UploadThumbnail
                    id={id}
                    editable
                    accept={accept}
                    fileChange={fileChange}
                    defaultValue={defaultValue}
                    type={type}
                /> 
            }

            {error && <span className="text-red-600 ml-3 text-xs">{error}</span>}
        </div>
    );
}

NewOrExistentResSwitch.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    fileChange: PropTypes.func,
    accept: PropTypes.string,
    error: PropTypes.string,
    existentItems: PropTypes.array,
    type: PropTypes.oneOf(RESOURCE_TYPES),
    defaultValue: PropTypes.string,
}

NewOrExistentResSwitch.defaultProps = {
    id: '',
    label: '',
    accept: 'image/*',
    fileChange: () => {},
    error: '',
    existentItems: [],
    type: RESOURCE_TYPES.IMAGE,
    defaultValue: null,
}

export default NewOrExistentResSwitch;