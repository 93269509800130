const downloadExcel = (url, name) => {
  const link = document.createElement('a');
  link.target = '_blank';
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
};

export default downloadExcel;
