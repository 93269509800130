import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import css from './UploadThumbnail.module.scss';
import { RESOURCE_TYPES } from '../Animation/ExistentResSelector';

const UploadThumbnail = ({
  id,
  img,
  editable,
  accept,
  fileChange,
  width,
  label,
  defaultValue,
  type,
}) => {
  const [image, setImage] = useState(img);
  const classes = [`h-32 w-full relative ${label && 'mt-8'}`, css.DetailThumbnail];
  
  if (editable) {
    classes.push(css['is-editable']);
  }

  useEffect(() => {
    if (img) {
      setImage(img);
    }
  }, [img]);

  return (
    <label
      htmlFor={id}
      className={classes.join(' ')}
      style={{
        background: `transparent url(${image || defaultValue || 'https://via.placeholder.com/400x200?text=Cargar+un+archivo'}) center/cover no-repeat`,
        width,
      }}
    >
      {(type === RESOURCE_TYPES.ZIP  && image) && <label className={classes.join(' ')} style={{ background : `transparent url('https://via.placeholder.com/400x200?text=Archivo+Cargado') center/cover no-repeat`,width, }}  />}
      
      {label && <p className="absolute -mt-8">{label}</p>}
      {editable && (
        <>
          <input
            id={id}
            className='hidden'
            type='file'
            accept={accept}
            onChange={(event) => {
              const { target } = event;
              if (target.files && target.files.length > 0) {
                fileChange(target.id, target.files[0]);
                setImage(URL.createObjectURL(target.files[0]));
              }
            }}
          />

          <div className={`${css['DetailThumbnail-overlay']} flex items-center justify-center`}>
            <CloudUploadIcon />
          </div>
        </>
      )}
    </label>
  );
};

UploadThumbnail.propTypes = {
  id: PropTypes.string.isRequired,
  img: PropTypes.string,
  editable: PropTypes.bool,
  accept: PropTypes.string,
  fileChange: PropTypes.func,
  width: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  type: PropTypes.oneOf(RESOURCE_TYPES),
};

UploadThumbnail.defaultProps = {
  img: '',
  editable: false,
  accept: 'image/jpg, image/png',
  fileChange: () => null,
  width: '',
  label: '',
  defaultValue: null,
  type: RESOURCE_TYPES.IMAGE,
};

export default UploadThumbnail;
