import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, Button, Grid,Switch
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import NewOrExistentResSwitch from './NewOrExistentResSwitch';
import { RESOURCE_TYPES } from './ExistentResSelector';



const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);

function Row({ row }) {
    return (
        <StyledTableRow key={row.id}>
            <StyledTableCell>{row.id}</StyledTableCell>
            <StyledTableCell>{row.match?.team_a_v2?.name}</StyledTableCell>
            <StyledTableCell>{row.match?.team_b_v2?.name}</StyledTableCell>
            <StyledTableCell>{row.match?.tournament_v2?.name}</StyledTableCell>
            <StyledTableCell>{row.match?.tournament_v2?.account?.name}</StyledTableCell>
        </StyledTableRow>
    );
}

function ListAnimation() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [state, setState] = useState({
        checkedC: false,
    });
    const assignCameramanState = useSelector((state) => ({
        status: state.animation.status,
        error: state.animation.error,
        list: state.animation.list,
    }));

    const [formFiles, setFormFiles] = useState({
        zip: null,
    });

    const [isSaving, setIsSaving] = useState(false);
    const [isWorking, setIsWorking] = useState(false);


    const {
        handleSubmit,
        reset
    } = useForm({
        submitFocusError: true,
    });


    const onSubmit = (values) => {
        setIsSaving(true);
        const formatedValues = {
            ...values,
        }
        const formData = getFormData(formatedValues);
        if (formFiles.zip) formData.append(typeof formFiles.zip === 'number' ? 'video_advertising_id' : 'zip', formFiles.zip);
         
        dispatch({ type: 'animation/update', payload: {reset:reset,saveRepeat: saveRepeat,setIsSaving:setIsSaving, values: formData, enqueueSnackbar } })
    }

    const getFormData = (data) => {
        const formData = new FormData();

        for(let [key, value] of Object.entries(data)) {
            if (Array.isArray(value)) {
                value.forEach((item, i) => {
                    formData.append(`${key}[${i}]`, item);
                })
            } else {
                formData.append(key, value);
            }
        }

        return formData;
    }


 
    
   

    const handleChangeSwitch = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        let checked = event.target.checked ? 1 : 0;

        setIsWorking(checked)
        const values = {params: {working:checked}}

        dispatch({ type: 'animation/list', payload: {values,enqueueSnackbar }});


    }

    const saveRepeat = () => {
        const values = {params: {working:isWorking}}
        dispatch({ type: 'animation/list', payload: {values,enqueueSnackbar }});
    }

    const handleVideosClick = () =>{
        const values = {params: {working:isWorking}}
        dispatch({ type: 'animation/downloadVideos', payload: {values,enqueueSnackbar } })
    }


    return (
        <main>
            {assignCameramanState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}


           

            <Paper elevation={0}>
                <div className='relative max-w-screen-xl p-8 mx-auto'>
                    <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
                        Animaciones
                    </Typography>
                    
                </div>
                
            </Paper>

            <div className='max-w-screen-xl p-8 mx-auto flex space-x-4 relative'>
                <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid className='text-white' item>Salir</Grid>
                    <Grid item>
                        <Switch checked={state.checkedC} onChange={handleChangeSwitch}  name="checkedC" />
                    </Grid>
                    <Grid className='text-white' item>Iniciar</Grid>
                </Grid>

            </div>

            <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3 pb-3'>
                <Button variant="outlined" color="primary"  disabled={assignCameramanState.status === ''} onClick={handleVideosClick}>
                    Descargar Videos
                </Button>
            </div>

            <div className='max-w-screen-xl p-8 mx-auto'>
                <TableContainer component={Paper}>
                    <Table className='w-full'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Id</StyledTableCell>
                                <StyledTableCell>Equipo A</StyledTableCell>
                                <StyledTableCell>Equipo B</StyledTableCell>
                                <StyledTableCell>Torneo</StyledTableCell>
                                <StyledTableCell>Cuenta</StyledTableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assignCameramanState.list?.data?.map((row, i) => <Row key={i} row={row}   />)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

                <form noValidate className='w-full' onSubmit={handleSubmit(onSubmit)} >
                    <Paper className='pl-12 p-8 my-8 grid grid-cols-2 gap-6 max-w-screen-xl p-8 mx-auto'>
                        <div className='grid grid-cols-1 md:grid-cols-1 gap-6'>
                            <NewOrExistentResSwitch
                                id="zip"
                                label="Cargar  Zip"
                                accept=".zip"
                                fileChange={(_, file) => setFormFiles((current) => ({...current, zip: file})) }
                                type={RESOURCE_TYPES.ZIP}
                            />
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-4 gap-3'>

                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                disabled={isSaving || assignCameramanState.status === ''}>
                                {isSaving ? 'Guardando...' : 'Guardar'}
                            </Button>
                        </div>
                    </Paper>

                </form>
        </main>
    );
}

export default ListAnimation;