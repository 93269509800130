import ax from '../../axios';
import downloadExcel from '../helpers/downloadExcel';

const animation = {
  state: {
    list: null,
    error: null,
    status: '',
  },
  reducers: {
    // Pending auditions
    report: (state) => ({ ...state, status: 'waiting', error: null }),
    list: (state) => ({ ...state, status: 'waiting', error: null }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    downloadVideos: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    excelSuccess: (state, res) => ({
      ...state,
      status: 'received',
      excelUrl: res,
    }),

    excelFailed: (state, error) => ({ ...state, status: 'failed', error }),
    update: (state) => ({ ...state, status: 'waiting', error: null }),
    updateSuccess: (state, res) => ({
        ...state,
        status: 'received',
        message: res.message,
    }),
    updateFailed: (state, error) => ({ ...state, status: 'failed', error }),
  },
  effects: (dispatch) => ({
    list(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/videos', values).then((res) => {
        dispatch.animation.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.animation.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    report(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/report', values).then((res) => {
        dispatch.animation.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.animation.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    downloadVideos(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/download-videos',values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.animation.excelSuccess(url);
        downloadExcel(url,'videos.zip');
      })
        .catch((error) => {
          let msg = error.message;
          console.log(error);
          console.log(msg);
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.animation.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    update(payload) {
      const {values, enqueueSnackbar,setIsSaving } = payload;

      ax.post(`/upload-videos`, values).then((res) => {
          enqueueSnackbar('Zip cargado correctamente', { variant: 'success' });
          dispatch.animation.updateSuccess(res);
          setIsSaving(false);
          
          setTimeout(() => {
            window.location.reload();
        }, 1000);
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.animation.updateFailed(error.response);
          setIsSaving(false);
          enqueueSnackbar(msg, { variant: 'error' });
      });
  },
  }),
};

export default animation;
